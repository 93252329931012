/**
 * Header component
 *
 * Top navigation bar for your site. Set to remain visible as the
 * user scrolls so that they can constantly reach any part of your page.
 */
import React from "react";

const Header = () => {
  return (
    <div
      style={{
        position: "fixed",
        fontWeight: "bold",
        display: "flex",
        justifyContent: "center",
        gap: "2rem",
        background: "white",
        padding: "1rem",
        top: 0,
        width: "100%",
        zIndex: 10,
      }}
    >

    <a href="#home" className="head">Home</a>
      <a href="#about" className="head">About</a>
      <a href="#portfolio"  className="head">Portfolio</a>
      <a href="#footer" className="head">Contact</a>
   
    </div>
  );
};

export default Header;
