// index.js or App.js (where your main component is rendered)
import React from 'react';
import { render } from 'react-dom';
import { BrowserRouter } from 'react-router-dom'; // Import BrowserRouter


import App from './App';

render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById('app')
);
