// Components/SorryPage.jsx
// import React from 'react';
// import './SorryPage.css';
// // Assuming 'images' is one level up from 'myFile.jsx'
// import batman from '../images/batmanmobile.jpg';


// const SorryPage = () => {
//   return (
//     <div className="sorry-container">
//       <h1 className="sorry-text">Sorry, this page is only available on laptops.</h1>
//     </div>
//   );
// };

// export default SorryPage;
// Components/MyFile.js
import React from 'react';
import './SorryPage.css';
import mynew from "../images/batmanmobile.jpg"; // Adjust the extension if needed

const SorryPage = () => {
  return (
    <div className="sorry-container" style={{ backgroundImage: `url(${mynew})` }}>
      <h1 className="sorry-text">Kazi Sagar Bista</h1>
      
    </div>
  );
};

export default SorryPage;
