import React, { useEffect, useState } from 'react';
import MyFile from './Components/myFile'; // Correct the component name
import About from './Components/About';
import Footer from './Components/Footer';
import Header from './Components/Header';
import Home from './Components/Home';
import Portfolio from './Components/Portfolio';

import './styles.css';

const siteProps = {
  name: 'Kazi Sagar Bista',
  title: 'Web Designer & Software Developer',
  email: 'Sagarbista098@gmail.com.com',
  gitHub: '',
  instagram: '#',
  linkedIn: '#',
  medium: '#',
  twitter: '#',
  youTube: '#',
};

const primaryColor = 'black';
const secondaryColor = '#D2F1E4';

const App = () => {
  const [isLaptop, setIsLaptop] = useState(false);

  useEffect(() => {
    const checkIsLaptop = () => {
      setIsLaptop(window.innerWidth >= 850);
    };

    // Check on initial mount
    checkIsLaptop();

    const handleResize = () => {
      checkIsLaptop();
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []); // Empty dependency array to run only on mount

  if (!isLaptop) {
    return (
      <div id="main">
        <MyFile /> {/* Correct the component name */}
      </div>
    );
  }

  return (
    <div id="main">
      <Header />
      <Home name={siteProps.name} title={siteProps.title} />
      <About />
      <Portfolio />
      <Footer {...siteProps} primaryColor={primaryColor} secondaryColor={secondaryColor} />
    </div>
  );
};

export default App;
