import React from "react";
import arrowSvg from "../images/down-arrow.svg";
import PropTypes from "prop-types";
import image from "../images/star1.gif";

const imageAltText =
  "Kazi Sagar Bista";

const Home = ({ name, title }) => {
  return (
    <section id="home" className="min-height" style={{ position: "relative" }}>
      <img className="background" src={image} alt="" />
     //<div style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
    
        <h1 style={{ textAlign: "center",color:"white",textAlign:"bottom" }} className="myname">{name}</h1>
        <h2 style={{ textAlign: "center",color:"white", textAlign: "bottom" }} className="mytitle">{title}</h2>
      
     </div>
      
      <div style={{ position: "absolute", bottom: "3rem", left: "50%", transform: "translateX(-50%)" }}>
        <img src={arrowSvg} style={{ height: "3rem", width: "3rem" }} alt={imageAltText} />
      </div>
    </section>
  );
};

Home.defaultProps = {
  name: "Bistakazi",
  title: "",
};

Home.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default Home;
